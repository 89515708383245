.ag-header-cell {
    width: 100%;
    padding: 0;
    margin: 0%;
}

.ag-theme-alpine .ag-header-cell {
    padding: 0;
    padding-left: 5px;
    align-items: center;
    text-align: center;
    font-size: 11px;
}

.ag-header-group-cell-label,
.ag-header-cell-label {
    overflow: visible;
}

/*.ag-icon {*/
/*  display: none;*/
/*}*/
.ag-theme-alpine .ag-cell {
    text-align: center;
    margin: 0;
    width: auto;
    padding: 0px;
    -webkit-font-smoothing: subpixel-antialiased;
}
