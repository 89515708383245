:root {
  --color-primary: #ff7225;
  --color-bg: #F8F8F8;
  --color-bg-modal: #ffffff;
  --color-bg-table: #ffffff;
  --color-bg-chart: #ffffff;
  --color-bg-positive: #dbfff3;
  --color-bg-negative: #ffedf3;
  --color-border-modal: #e2e2e2;
  --color-text: #000000;
  --color-text-positive: #25bd8a;
  --color-text-negative: #ff0c5d;
  --color-text-pnl-actual: #00965f;
  --color-text-pnl-budget: #0063d8;
  --color-text-pnl-forecast: #bb143c;
  --color-icon-nav-selected: #263646;
  --color-icon-nav-inactive: #9199a2;
}

.color-text {
  color: var(--color-text);
}

.color-text-positive {
  color: var(--color-text-positive);
}

.color-text-negative {
  color: var(--color-text-negative);
}
