body,
body > #root {
  background: var(--color-bg);
}

.font-weight-bold {
  font-weight: 500;
}

details > summary {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.underline-on-hover:hover {
  text-decoration: underline;
}
